<template>
  <div>
    <div id="dioLayer" @click="layer"></div>
    <div id="dioBox">
      <img v-show="tiptype" class="dioimg" src="../assets/image/ic_pop_success.png" alt="">
      <div v-show="tiptype" class="diotip">{{feedbacksuccess}}</div>

      <img v-show="!tiptype" class="dioimg" src="../assets/image/ic_pop_error.png" alt="">
      <div v-show="!tiptype" class="diotip">{{erromsg}}</div>
      <div class="diobutton">
        <button class="button" style="border-bottom-left-radius:10px" @click="diolayer">OK</button>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {
    tiptype: {
      type: Boolean
    },
    feedbacksuccess: {
      type: String
    },
    erromsg: {
      type: String
    },
    isOver: {
      type: Boolean
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    diolayer () {
      if (this.isOver) {
        this.$emit('closedio',true)
      } else {
        this.$emit('closedio')
      }
      document.getElementById("dioBox").style.display = "none"
      document.getElementById("dioLayer").style.display = "none"
    },
  },
  filters: {},
  mounted () {
      document.getElementById("dioBox").style.display = "block"
      document.getElementById("dioLayer").style.display = "block"
  },
  created () { }
}
</script>

<style scoped>
  /* // 弹出框 */
  /* // 背景层 */
  #dioLayer {
    /* height: 812px; */
    display: none;
    background-color: rgb(16, 21, 30);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    /* -moz-opacity: 0.8;
    opacity: 0.85; */
  }
  /*弹出层*/
  #dioBox {
    display: none;
    background-color: #FFFFFF;
    z-index: 11;
    width: 300px;
    height: 270px;
    position:fixed;
    top:0;
    right:0;
    left:0;
    bottom:0;
    margin:auto;
    border-radius: 10px;
  }
  #dioBox .close{
      text-align: right;
      margin-right: 5px;
      background-color: #F8F8F8;
  }
  /*关闭按钮*/
  #dioBox .close a {
      text-decoration: none;
      color: #2D2C3B;
  }

  .dioimg {
    width: 40px;
    height: 40px;
    margin-top: 25px;
    margin-bottom: 20px;
    vertical-align:bottom;
  }
  .diotip {
    /* //styleName: 拉丁语系/正文; */
    font-family: Avenir;
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin: 0 15px;
    color: #0E131B;
  }
  .diobutton {
    position: absolute;
    border-top: 1px solid rgba(14,19,27,10%);
    bottom: 0;
    width: 100%;
  }
  .button {
    color: #FD6700;
    border: none;
    background-color: #fff;
    width: 150px;
    height: 50px;
    /* //styleName: 拉丁语系/主按钮; */
    font-family: Avenir;
    font-size: 14px;
    font-style: italic;
    font-weight: 900;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: center;
  }
  .rightbutton {
    font-size: 14px;
    border-left: 1px solid rgba(14,19,27,10%);
    color: #FD6700;
  }
</style>
